import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IoSearch, IoLogOut, IoChevronDown } from "react-icons/io5";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { CgProfile } from "react-icons/cg";
import logo from '../assets/logo_white_hz.png';

function Navbar() {
  const menuItems = [
    { name: 'Ads', icon: HiOutlineSpeakerphone, route: '/ads' },
    { name: 'Competitors Finder', icon: IoSearch, route: '/competitors' }
  ];
  const navigate = useNavigate();

  const userString = localStorage.getItem('user');
  const user = userString ? JSON.parse(userString) : null;

  const userInfo = user ? {
    isLoggedIn: true,
    id: user.id,
    email: user.email,
    name: user.name,
    surname: user.surname,
    phone_number: user.phone_number,
    paying_plan: user.paying_plan,
    profileImage: user.profile_picture || 'https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png' 
  } : {
    isLoggedIn: false
  };

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleNavigation = (route) => {
    if (window.handleNavigation) {
      window.handleNavigation(route);
    } else {
      navigate(route);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('user')
    navigate('/');
  };

  return (
    <nav className="flex justify-between items-center px-8 py-4 bg-white fixed top-0 left-0 right-0 z-10000 border-b border-gray-400 h-[70px]">
      <Link to="/">
        <img src={logo} alt="Close Scout" className="h-[30px]" />
      </Link>
      <div className="flex gap-4">
        {menuItems.map((item) => (
          <button 
            key={item.name} 
            className="bg-transparent border-none cursor-pointer text-base py-1 px-3 rounded-full transition-colors duration-300 hover:bg-background whitespace-nowrap flex items-center"
            onClick={() => handleNavigation(item.route)}
          >
            <item.icon className="mr-2 text-gray-400" /> {item.name}
          </button>
        ))}
      </div>
      <div className="flex items-center">
        {userInfo.isLoggedIn ? (
          <div className="relative flex items-center">
            <div className="text-text mr-4">
              <p className="font-semibold">{userInfo.name} {userInfo.surname}</p>
              <p className="text-sm text-text">{userInfo.email}</p>
            </div>
            <div 
              className="flex items-center cursor-pointer"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              <img src={userInfo.profileImage} alt="Profile" className="w-8 h-8 rounded-full" />
              <IoChevronDown className="ml-1 text-gray-600" />
            </div>
            {isMenuOpen && (
              <div className="absolute right-0 top-full mt-2 w-48 bg-white rounded-md shadow-lg py-1">
                <button 
                  onClick={() => handleNavigation('/profile')}
                  className="w-full text-left px-4 py-2 text-sm text-text hover:bg-gray-100 flex items-center cursor-pointer"
                >
                  <CgProfile className="mr-2 text-gray-300" />
                  Profile
                </button>
                <button 
                  onClick={handleLogout}
                  className="w-full text-left px-4 py-2 text-sm text-text hover:bg-gray-100 flex items-center cursor-pointer"
                >
                  <IoLogOut className="mr-2 text-error" />
                  Log Out
                </button>
              </div>
            )}
          </div>
        ) : (
          <>
            <Link 
              to="/auth"
              className="py-2 px-4 ml-4 border-none rounded cursor-pointer text-base bg-background text-text hover:bg-gray-300 transition duration-100"
            >
              Log In
            </Link>
            <Link 
              to="/auth"
              className="py-2 px-4 ml-4 border-none rounded cursor-pointer text-base bg-primary text-white hover:bg-text transition duration-100"
            >
              Sign Up
            </Link>
          </>
        )}
      </div>
    </nav>
  );
}

export default Navbar;