import React from 'react';
import Navbar from '../components/Navbar';

const TermsAndConditionsPage = () => {
  return (
    <div className="min-h-screen bg-background">
      <Navbar />
      <div className="container max-w-5/6 mt-20 mx-auto px-4 py-12">
        <h1 className="text-4xl font-bold mb-8 text-primary">Terms and Conditions</h1>
        <div className="bg-white shadow-lg rounded-lg p-8">
          <p className="text-sm text-gray-500 mb-6">Last updated: Oct 1st 2024</p>
          
          <Section title="1. Introduction">
            <p>Welcome to Close Scout. These Terms and Conditions govern your use of our website and services. By accessing or using Close Scout, you agree to be bound by these Terms. If you disagree with any part of the terms, you may not access the service.</p>
          </Section>
          
          <Section title="2. Use of Service">
            <p>You may use our service only as permitted by law and these Terms. You agree not to:</p>
            <ul className="list-disc pl-6 mt-2 space-y-1">
              <li>Use the service for any illegal purpose or in violation of any local, state, national, or international law</li>
              <li>Violate or encourage others to violate the rights of third parties, including intellectual property rights</li>
              <li>Attempt to gain unauthorized access to the service or its related systems or networks</li>
              <li>Interfere with or disrupt the integrity or performance of the service</li>
              <li>Reproduce, duplicate, copy, sell, resell or exploit any portion of the service without express written permission from us</li>
            </ul>
          </Section>
          
          <Section title="3. User Accounts">
            <p>When you create an account with us, you must provide accurate, complete, and up-to-date information. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account on our service.</p>
          </Section>
          
          <Section title="4. Intellectual Property">
            <p>The service and its original content, features, and functionality are and will remain the exclusive property of Close Scout and its licensors. The service is protected by copyright, trademark, and other laws of both the United States and foreign countries.</p>
          </Section>
          
          <Section title="5. Termination">
            <p>We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the service will immediately cease.</p>
          </Section>
          
          <Section title="6. Limitation of Liability">
            <p>In no event shall Close Scout, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the service.</p>
          </Section>
          
          <Section title="7. Disclaimer">
            <p>Your use of the service is at your sole risk. The service is provided on an "AS IS" and "AS AVAILABLE" basis. The service is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.</p>
          </Section>
          
          <Section title="8. Governing Law">
            <p>These Terms shall be governed and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law provisions.</p>
          </Section>
          
          <Section title="9. Changes to Terms">
            <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will provide notice of any significant changes by posting the new Terms on this page and updating the "Last updated" date at the top of this page.</p>
          </Section>
          
          <Section title="10. Contact Us">
            <p>If you have any questions about these Terms, please contact us at: <a href="mailto:p@vermu.com" className="text-primary hover:underline">p@vermu.com</a></p>
          </Section>
        </div>
      </div>
    </div>
  );
};

const Section = ({ title, children }) => (
  <div className="mb-8">
    <h2 className="text-2xl font-semibold mb-4 text-text">{title}</h2>
    <div className="text-gray-700 space-y-3">{children}</div>
  </div>
);

export default TermsAndConditionsPage;