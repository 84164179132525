import React from 'react';

export const Card = ({ children, className, onClick, variant = 'default' }) => {
  const baseClasses = 'bg-white rounded-lg shadow-lg mb-4 border border-border border-opacity-70';
  const variantClasses = {
    default: 'p-4 flex flex-col items-center text-center',
    result: 'p-8 flex flex-col items-start text-left'
  };
  const hoverClasses = onClick ? 'cursor-pointer hover:bg-gray-50 transition-colors' : '';

  return (
    <div 
      className={`${baseClasses} ${variantClasses[variant]} ${hoverClasses} ${className}`}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export const CardHeader = ({ children, className }) => (
  <div className={`mb-5 ${className}`}>{children}</div>
);

export const CardTitle = ({ children, className }) => (
  <h2 className={`text-2xl font-bold text-text m-0 ${className}`}>{children}</h2>
);

export const CardContent = ({ children, className }) => (
  <div className={`text-text ${className}`}>{children}</div>
);