import React from 'react';

const Input = ({ variant = 'default', ...props }) => {
  const baseClasses = 'w-full py-2 px-3 text-base transition-colors duration-300 rounded-md focus:outline-none';
  
  const variantClasses = {
    default: 'bg-white text-text placeholder-gray-500 focus:ring-2 focus:ring-secondary',
    auth: 'mt-2 w-full bg-white text-text placeholder-gray-500 border border-gray-300 mb-4 text-left',
    search: 'bg-white text-text placeholder-gray-500 rounded-md'
  };

  const classes = `${baseClasses} ${variantClasses[variant]}`;

  return <input className={classes} {...props} />;
};

export default Input;