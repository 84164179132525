import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { subDays, subMonths, subYears, startOfYear, parseISO } from 'date-fns';
import Input from '../components/ui/Input';
import Button from '../components/ui/Button';
import { Card, CardTitle, CardContent } from '../components/ui/Card';
import { findAds, getAdMetrics, calculateMetrics } from '../services/api';
import NavbarLogged from '../components/NavbarLogged';
import ErrorMessage from '../components/ui/ErrorMessage';
import AdMetricsChart from '../components/AdMetricsChart';
import AdAnalytics from '../components/AdAnalytics';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import AdTable from '../components/AdTable';
import Spinner from '../components/ui/Spinner'; // Make sure to create this component

const AdsPage = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const [domain, setDomain] = useState(state?.domain || '');
  const [ads, setAds] = useState({ total_ads: [], ads: [] });
  const [adMetrics, setAdMetrics] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedView, setSelectedView] = useState('daily');
  const [selectedTimeRange, setSelectedTimeRange] = useState('1year');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
  const [filters, setFilters] = useState({
    status: 'all',
    firstShowed: '',
    lastShowed: '',
    campaignDuration: ''
  });
  const [metrics, setMetrics] = useState({
    growthPercentage: 0,
    averageCampaignDuration: 0,
    adRotationRate: 0
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [adsPerPage] = useState(10);
  const [isLoadingAds, setIsLoadingAds] = useState(false);
  const [isLoadingMetrics, setIsLoadingMetrics] = useState(false);

  const fetchData = useCallback(async (domain) => {
    setIsLoadingAds(true);
    setIsLoadingMetrics(true);
    setError(null);

    try {
      // First, fetch ads data
      const adsResult = await findAds(domain);
      
      if (!adsResult || adsResult.length === 0) {
        throw new Error('No ads data available for this domain.');
      }

      setAds({
        raw_ads: adsResult,
        ads: adsResult
      });
      setIsLoadingAds(false);

      // Then, use the ads result to fetch metrics
      const metricsResult = await getAdMetrics(domain, adsResult);

      setAdMetrics(metricsResult);
      const calculatedMetrics = calculateMetrics(metricsResult, selectedTimeRange);
      setMetrics(calculatedMetrics);
      setIsLoadingMetrics(false);

    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error.message || 'An error occurred while fetching data.');
    } finally {
      setIsLoadingAds(false);
      setIsLoadingMetrics(false);
    }
  }, [selectedTimeRange]);

  const handleInputChange = (e) => setDomain(e.target.value);
  const handleSearch = () => domain && fetchData(domain);

  useEffect(() => {
    if (domain) {
      const authToken = localStorage.getItem('access_token');
      if (!authToken) {
        navigate('/auth');
      }
    }
  }, [domain, navigate]);

  const prepareChartData = (data) => {
    if (!data || typeof data !== 'object') {
      console.warn('Invalid data provided to prepareChartData:', data);
      return [];
    }

    const sortedEntries = Object.entries(data).sort(([a], [b]) => new Date(a) - new Date(b));
    const filteredEntries = filterDataByTimeRange(sortedEntries);
    return filteredEntries.map(([date, count]) => ({ date, count }));
  };

  const filterDataByTimeRange = useCallback((entries) => {
    const now = new Date();
    let startDate;

    switch (selectedTimeRange) {
      case '5days':
        startDate = subDays(now, 5);
        break;
      case '1month':
        startDate = subMonths(now, 1);
        break;
      case 'YTD':
        startDate = startOfYear(now);
        break;
      case '1year':
        startDate = subYears(now, 1);
        break;
      case '2years':
        startDate = subYears(now, 2);
        break;
      case '5years':
        startDate = subYears(now, 5);
        break;
      case 'all':
      default:
        return entries;
    }

    return entries.filter(([date]) => {
      if (selectedView === 'weekly') {
        return parseISO(date.split('-W')[0] + '-01') >= startDate;
      } else if (selectedView === 'yearly') {
        return parseISO(date + '-01-01') >= startDate;
      }
      return new Date(date) >= startDate;
    });
  }, [selectedTimeRange, selectedView]);

  const timeRangeOptions = [
    { value: '5days', label: '5D' },
    { value: '1month', label: '1M' },
    { value: 'YTD', label: 'YTD' },
    { value: '1year', label: '1Y' },
    { value: '2years', label: '2Y' },
    { value: '5years', label: '5Y' },
    { value: 'all', label: 'ALL' },
  ];

  const sortedAndFilteredAds = useMemo(() => {
    if (!ads.ads) return [];
    let filteredAds = [...ads.ads];

    // Apply filters
    if (filters.status !== 'all') {
      filteredAds = filteredAds.filter(ad => {
        const endDate = new Date(parseInt(ad.end_date.timestamp) * 1000);
        return filters.status === 'active' ? endDate > new Date() : endDate <= new Date();
      });
    }
    if (filters.firstShowed) {
      filteredAds = filteredAds.filter(ad => new Date(parseInt(ad.start_date.timestamp) * 1000) >= new Date(filters.firstShowed));
    }
    if (filters.lastShowed) {
      filteredAds = filteredAds.filter(ad => new Date(parseInt(ad.end_date.timestamp) * 1000) <= new Date(filters.lastShowed));
    }
    if (filters.campaignDuration) {
      filteredAds = filteredAds.filter(ad => {
        const duration = (parseInt(ad.end_date.timestamp) - parseInt(ad.start_date.timestamp)) / (24 * 60 * 60);
        return duration >= parseInt(filters.campaignDuration);
      });
    }

    // Apply sorting
    if (sortConfig.key) {
      filteredAds.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }

    return filteredAds;
  }, [ads.ads, sortConfig, filters]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const getSortIcon = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? <FaSortUp className="inline ml-1" /> : <FaSortDown className="inline ml-1" />;
    }
    return <FaSort className="inline ml-1" />;
  };
  
  const getFaviconUrl = (domain) => {
    return `https://www.google.com/s2/favicons?domain=${domain}&sz=32`;
  };

  const renderContent = () => {
    return (
      <>
        <NavbarLogged />
        <div className="container mx-auto px-4 py-8">
          <h1 className="text-4xl font-bold mb-8 text-primary">Search Competitor Ads</h1>
          
          <div className="mb-12">
            <div className="flex flex-col sm:flex-row gap-4 items-start">
              <Input
                type="text"
                value={domain}
                onChange={handleInputChange}
                placeholder="Enter competitor domain (e.g., competitor.com)"
                variant="search"
                className="w-full sm:w-1/4 p-2 rounded-md border border-text" 
              />
              <Button
                onClick={handleSearch}
                variant="primary"
                className="mt-2 sm:mt-0"
              >
                Search
              </Button>
            </div>
          </div>

          {error && <ErrorMessage message={error} />}

          {isLoadingMetrics ? (
            <div className="flex justify-center items-center h-64">
              <Spinner />
            </div>
          ) : (
            <>
              {adMetrics ? (
                <Card variant="result" className="mb-8">
                  <CardTitle className="mb-8 text-center flex items-center justify-center">
                    <img src={getFaviconUrl(domain)} alt={`${domain} favicon`} className="mr-2 w-6 h-6" />
                    {domain}
                  </CardTitle>
                  <CardContent className="w-full">
                    <div className="flex flex-wrap gap-4 mb-4 justify-left">
                      <div>
                        <label htmlFor="view-selector" className="mr-2 text-text">View:</label>
                        <select
                          id="view-selector"
                          value={selectedView}
                          onChange={(e) => setSelectedView(e.target.value)}
                          className="border border-border rounded p-1 text-text bg-white focus:outline-none "
                        >
                          <option value="daily">Daily</option>
                          <option value="weekly">Weekly</option>
                          <option value="monthly">Monthly</option>
                          <option value="yearly">Yearly</option>
                        </select>
                      </div>
                      <div className="flex items-center">
                        <span className="mr-2 text-text">Time Range:</span>
                        <div className="flex">
                          {timeRangeOptions.map((option, index) => (
                            <button
                              key={option.value}
                              onClick={() => setSelectedTimeRange(option.value)}
                              className={`px-3 py-1 text-sm ${
                                selectedTimeRange === option.value
                                  ? 'bg-primary text-white'
                                  : 'bg-white text-text hover:bg-gray-100'
                              } focus:outline-none ${
                                index === 0 ? 'border-l border-t border-b rounded-l' : 
                                index === timeRangeOptions.length - 1 ? 'border-r border-t border-b rounded-r' : 
                                'border-t border-b'
                              } border-border`}
                            >
                              {option.label}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-8">
                      <div className="md:col-span-2">
                        <h3 className="text-xl font-semibold mb-4 text-text text-center">
                          Number of different ads showed over time
                        </h3>
                        {adMetrics && adMetrics.timeline ? (
                          <AdMetricsChart data={prepareChartData(adMetrics.timeline)} />
                        ) : (
                          <p className="text-center text-text">No ad metrics data available for this domain.</p>
                        )}
                      </div>
                      <div>
                        <AdAnalytics 
                          currentAdCount={adMetrics ? adMetrics.total_ads : 0}
                          growthPercentage={metrics.growthPercentage}
                          averageCampaignDuration={metrics.averageCampaignDuration}
                          adRotationRate={metrics.adRotationRate}
                        />
                      </div>
                    </div>
                  </CardContent>
                </Card>
              ) : (
                <p className="text-center text-text">No ad metrics data available.</p>
              )}
            </>
          )}

          {isLoadingAds ? (
            <div className="flex justify-center items-center h-64">
              <Spinner />
            </div>
          ) : (
            <>
              {ads && ads.ads && ads.ads.length > 0 ? (
                <AdTable
                  ads={sortedAndFilteredAds} 
                  filters={filters}
                  handleFilterChange={handleFilterChange}
                  requestSort={requestSort}
                  getSortIcon={getSortIcon}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  adsPerPage={adsPerPage}
                />
              ) : (
                <p className="text-center text-text">No ads data available.</p>
              )}
            </>
          )}
        </div>
      </>
    );
  };

  return renderContent();
};

export default AdsPage;