import React from 'react';
import { BsBarChartFill, BsQuestionCircle } from "react-icons/bs";

const AdAnalytics = ({ currentAdCount, growthPercentage, averageCampaignDuration, adRotationRate }) => {

  const AnalyticItem = ({ label, value, color, description }) => (
    <div className="mb-4 relative">
      <div className="flex items-center">
        <p className="text-sm text-gray-500 mb-1">{label}</p>
        {description && (
          <div className="ml-2 relative group">
            <BsQuestionCircle className="text-gray-400 w-4 h-4" />
            <div className="absolute bottom-full left-0 bg-white border border-gray-200 text-text text-xs rounded py-2 px-3 mb-2 opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap shadow-md">
              {description}
            </div>
          </div>
        )}
      </div>
      <p className={`text-2xl font-bold ${color}`}>{value}</p>
    </div>
  );

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-8">
      <div className="flex items-center mb-6">
        <div className="rounded-lg md:rounded-xl border-2 rotate-3 mx-1 bg-white shadow-lg w-4 h-4 md:h-10 md:w-10 border-secondary inline-flex items-center justify-center">
          <BsBarChartFill className="w-4 h-4 md:w-7 md:h-7 text-secondary" />
        </div>
        <h2 className="text-xl font-semibold text-text ml-4">Analytics</h2>
      </div>
      <div className="space-y-4">
        <AnalyticItem
          label="Total ads found"
          value={currentAdCount}
          color="text"
          description="The total number of ads tracked in the system."
        />
        <AnalyticItem
          label="Growth"
          value={growthPercentage === 0 ? 'N/A' : `${growthPercentage >= 0 ? '+' : ''}${growthPercentage.toFixed(2)}%`}
          color={growthPercentage > 0 ? 'text-success' : growthPercentage < 0 ? 'text-error' : 'text-text'}
          description="Percentage change in ad count compared to the beginning period."
        />
        <AnalyticItem
          label="Avg. Campaign Duration"
          value={averageCampaignDuration === 0 ? 'N/A' : `${averageCampaignDuration.toFixed(1)} days`}
          color="text"
          description="The average length of time ads remain active."
        />
        <AnalyticItem
          label="Ad Rotation Rate"
          value={adRotationRate === 0 ? 'N/A' : `${adRotationRate.toFixed(2)}/month`}
          color="text"
          description="The average number of times ads are replaced or updated per month."
        />
      </div>
    </div>  
  );
};

export default AdAnalytics;