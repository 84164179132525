import React from 'react';
import { FaUser } from 'react-icons/fa';

const ReviewSection = () => {
  return (
    <div className="px-6 py-16 w-full flex justify-center items-center">
      <div className="max-w-3/5 text-center">
        <p className=" max-w-screen-md text-2xl italic mb-4">
          "Close Scout has revolutionized the way we analyze our competitors' ad strategies. It's an indispensable tool for our marketing team!"
        </p>
        <div className="flex items-center justify-center font-semibold mt-8">
          <FaUser className="text-secondary mr-2 w-6 h-6" />
          <span className="text-l text-text">from happy customer</span>
        </div>
      </div>
    </div>
  );
};

export default ReviewSection;   
