import React from 'react';

function Separator({ variant = 'default', className = '' }) {
  const variantClasses = {
    default: 'w-full border-text',
    light: 'w-full border-text/30',
    small: 'w-1/3 border-border mx-auto'
  };

  return <hr className={`${variantClasses[variant]} ${className}`} />
}

export default Separator;