import React from 'react';
import { createRoot } from 'react-dom/client'; // Updated import
import { GoogleOAuthProvider } from '@react-oauth/google'; // New import
import './index.css';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container); 

root.render( 
  <GoogleOAuthProvider clientId="963001685375-0412m24kekgco30pbg4rgqbr1rlk8rk9.apps.googleusercontent.com"> 
    <App />
  </GoogleOAuthProvider>
);