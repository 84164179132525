import React, { useState, useEffect } from 'react';
import NavbarLogged from '../components/NavbarLogged';
import { Card, CardHeader, CardTitle, CardContent } from '../components/ui/Card';
import { FaUser } from 'react-icons/fa';

const ProfilePage = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const userString = localStorage.getItem('user');
    if (userString) {
      setUser(JSON.parse(userString));
    }
  }, []);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <NavbarLogged />
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-4xl font-bold mb-8 text-primary">Your Profile</h1>
        <Card className="max-w-2xl mx-auto">
          <CardHeader>
            <CardTitle className="flex items-center">
              <FaUser className="mr-2" />
              Profile Information
            </CardTitle>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <ProfileField label="Name" value={`${user.name} ${user.surname}`} />
              <ProfileField label="Email" value={user.email} />
              {user.phone_number && (
                <ProfileField label="Phone Number" value={user.phone_number} />
              )}
              <ProfileField label="Plan" value={user.paying_plan || 'Free'} />
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

const ProfileField = ({ label, value }) => (
  <div>
    <span className="font-semibold">{label}:</span> {value}
  </div>
);

export default ProfilePage;