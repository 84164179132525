import React, { useState, useEffect } from 'react';
import { format } from 'date-fns';
import { fetchCreativeById } from '../services/api';
import { MdBrokenImage } from "react-icons/md";
import ErrorMessage from './ui/ErrorMessage';

const AdTable = ({
  ads,
  filters,
  handleFilterChange,
  requestSort,
  getSortIcon,
  currentPage,
  setCurrentPage,
  adsPerPage
}) => {
  console.log('Received ads in AdTable:', ads);
  const [creatives, setCreatives] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const indexOfLastAd = currentPage * adsPerPage;
  const indexOfFirstAd = indexOfLastAd - adsPerPage;
  const currentAds = ads.slice(indexOfFirstAd, indexOfLastAd);
  console.log('Current ads:', currentAds);

  useEffect(() => {
    const loadCreatives = async () => {
      setIsLoading(true);
      const creativePromises = currentAds.map(ad => 
        fetchCreativeById(ad.advertiser_id, ad.ad_id)
      );
      try {
        const creativeResults = await Promise.all(creativePromises);
        const newCreatives = {};
        currentAds.forEach((ad, index) => {
          newCreatives[ad.ad_id] = creativeResults[index];
        });
        setCreatives(prevCreatives => ({ ...prevCreatives, ...newCreatives }));
      } catch (error) {
        console.error('Error fetching creatives:', error);
        setError('Failed to load creative data');
      } finally {
        setIsLoading(false);
      }
    };

    if (currentAds.length > 0) {
      loadCreatives();
    }
  }, [currentAds]);

  const renderCreative = (ad) => {
    console.log('Rendering creative for ad:', ad);
    if (isLoading) {
      return (
        <div className="flex justify-center items-center h-full">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      );
    }
    if (error) {
      return <ErrorMessage message={error} />;
    }
    if (!ad.content || !ad.content.image || !ad.content.image.url) {
      console.log('No creative content available for ad:', ad.ad_id);
      return (
        <div className="flex flex-col items-center justify-center h-full">
          <MdBrokenImage className="text-lg text-gray-400 mb-2" />
          <p className="text-sm text-gray-500">Not available</p>
        </div>
      );
    }
    console.log('Rendering creative HTML:', ad.content.image.url);
    return <div dangerouslySetInnerHTML={{ __html: ad.content.image.url }} />;
  };

  return (
    <div className="overflow-x-auto">
      <div className="mb-6">
        <h4 className="text-lg font-semibold mb-3">Filters:</h4>
        <div className="flex flex-wrap gap-4">
          <select
            name="status"
            value={filters.status}
            onChange={handleFilterChange}
            className="border border-secondary rounded p-2 text-text bg-white focus:outline-none"
          >
            <option value="all">All Status</option>
            <option value="active">Active</option>
            <option value="inactive">Inactive</option>
          </select>
          <input
            type="date"
            name="firstShowed"
            value={filters.firstShowed}
            onChange={handleFilterChange}
            className="border border-secondary rounded p-2 text-text bg-white focus:outline-none"
          />
          <input
            type="date"
            name="lastShowed"
            value={filters.lastShowed}
            onChange={handleFilterChange}
            className="border border-secondary rounded p-2 text-text bg-white focus:outline-none"
          />
          <input
            type="number"
            name="campaignDuration"
            value={filters.campaignDuration}
            onChange={handleFilterChange}
            className="border border-secondary rounded p-2 text-text bg-white focus:outline-none"
            placeholder="Min Campaign Duration"
          />
        </div>
      </div>
      <table className="w-full text-sm text-left text-text">
        <thead className="text-xs uppercase text-text">
          <tr>
            <th scope="col" className="px-6 py-3 cursor-pointer bg-secondary" onClick={() => requestSort('ad_id')}>
              Ad ID {getSortIcon('ad_id')}
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer bg-secondary">
              Creative
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer bg-secondary">
              Ad Format
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer bg-secondary" onClick={() => requestSort('start_date')}>
              First Showed {getSortIcon('start_date')}
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer bg-secondary" onClick={() => requestSort('end_date')}>
              Last Showed {getSortIcon('end_date')}
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer bg-secondary" onClick={() => requestSort('campaign_duration')}>
              Campaign Duration {getSortIcon('campaign_duration')}
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer bg-secondary">
              Landing Page
            </th>
            <th scope="col" className="px-6 py-3 cursor-pointer bg-secondary">
              Advertiser
            </th>
          </tr>
        </thead>
        <tbody>
          {currentAds.map((ad, index) => (
            <tr key={ad.ad_id} className={index % 2 === 0 ? 'bg-white' : 'bg-background hover:bg-secondary/10'}>
              <td className="px-6 py-4 font-medium">{ad.ad_id}</td>
              <td className="px-6 py-4 h-48">{renderCreative(ad)}</td>
              <td className="px-6 py-4">{ad.ad_format || 'N/A'}</td>
              <td className="px-6 py-4">
                {ad.first_showed
                  ? format(new Date(ad.first_showed), 'PPP')
                  : 'N/A'}
              </td>
              <td className="px-6 py-4">
                {ad.last_showed
                  ? format(new Date(ad.last_showed), 'PPP')
                  : 'N/A'}
              </td>
              <td className="px-6 py-4">{ad.campaign_duration ? `${ad.campaign_duration} days` : 'N/A'}</td>
              <td className="px-6 py-4">
                {ad.destination_landing ? (
                  <a href={`https://${ad.destination_landing}`} target="_blank" rel="noreferrer nofollow" className='hover:underline'>
                    {ad.destination_landing}
                  </a>
                ) : 'N/A'}
              </td>
              <td className="px-6 py-4">{ad.advertiser_name || 'N/A'}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex justify-center mt-4">
        {Array.from({ length: Math.ceil(ads.length / adsPerPage) }, (_, i) => (
          <button
            key={i}
            onClick={() => setCurrentPage(i + 1)}
            className={`mb-1 px-3 py-1 rounded ${
              currentPage === i + 1 ? 'bg-primary text-white' : 'bg-white text-text hover:bg-gray-100'
            }`}
          >
            {i + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AdTable;