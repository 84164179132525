import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const PaymentSuccess = () => {
  const [status, setStatus] = useState(null);
  const location = useLocation();

  useEffect(() => {
    const sessionId = new URLSearchParams(location.search).get('session_id');
    if (sessionId) {
      fetch(`/payment-success?session_id=${sessionId}`)
        .then(response => response.json())
        .then(data => {
          setStatus(data.status);
        });
    }
  }, [location]);

  if (status === 'success') {
    return <h1>Thank you for your purchase!</h1>;
  } else if (status === 'processing') {
    return <h1>Payment processing. We'll update you when payment is received.</h1>;
  } else if (status === 'failed') {
    return <h1>Payment failed. Please try again.</h1>;
  } else {
    return <h1>Loading...</h1>;
  }
};

export default PaymentSuccess;