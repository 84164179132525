import React from 'react';
import { FaCheck } from 'react-icons/fa';
import { Card } from './ui/Card';
import CTAButton from './ui/CTAButton';

const PricingTier = ({ name, price, features, isPopular }) => (
  <Card className={`flex flex-col p-6 ${isPopular ? 'border-primary' : 'border-border'}`} >
    {isPopular && (
      <span className="bg-primary text-white px-3 py-1 rounded-full text-sm font-semibold self-start mb-4">
        Most Popular
      </span>
    )}
    <h3 className="text-2xl font-bold mb-2">{name}</h3>
    <div className="mb-4">
      <span className="text-4xl font-bold">${price}</span>
      <span className="text-text-secondary">/month</span>
    </div>
    <ul className="mb-6 flex-grow">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center mb-2">
          <FaCheck className="text-primary mr-2" />
          <span>{feature}</span>
        </li>
      ))}
    </ul>
    <CTAButton variant={isPopular ? 'primary' : 'secondary'} className="w-full">
      Get Started
    </CTAButton>
  </Card>
);

const PricingSection = () => {
  const pricingTiers = [
    {
      name: 'Free',
      price: 0.00,
      features: [
        'Up to 3 competitor domains',
      ],
      isPopular: false,
    },
    {
      name: 'Basic',
      price: 19.99,
      features: [
        'Up to 10 competitor domains',
        'Weekly ad reports',
        'Analytics',
      ],
      isPopular: false,
    },
    {
      name: 'Pro',
      price: 89.99,
      features: [
        'Up to 100 competitor domains',
        'Daily, Weekly and Monthly ad reports',
        'Analytics',
        'Email notifications',
      ],
      isPopular: true,
    },
    {
      name: 'Enterprise',
      price: 1499.99,
      features: [
        'Unlimited competitor domains',
        'Real-time ad tracking',
        'Custom reporting',
        'API access',
        'Dedicated support',
      ],
      isPopular: false,
    },
  ];

  return (
    <section className="py-16 bg-background">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-text text-center mb-12">Choose Your Plan</h2>
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
        {pricingTiers.map((tier, index) => (
            <PricingTier key={index} {...tier} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default PricingSection;
